




















import { Action } from "vuex-class";
import { Component, Vue, Ref } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";

@Component({
  components: {
    FormBase,
    FormInput,
    FormError,
    FormSubmit,
  },
})
export default class PagePasswordReset extends Vue {
  @Action("auth/passwordReset") passwordReset!: (payload: PasswordResetPayload) => Promise<{}>;

  @Ref() form!: FormClass;

  errorResponse: ErrorResponse = {
    status: 0,
    errors: [],
  };

  payload: PasswordResetPayload = {
    email: "",
    password_reset_token: "",
    password: "",
    password_confirmation: "",
  };

  completed = false;

  private mounted() {
    if (this.$route.query.token) {
      this.payload.password_reset_token = `${this.$route.query.token}`;
    }
    if (this.$route.query.email) {
      this.payload.email = `${this.$route.query.email}`;
    }
    if (!this.payload.email || !this.payload.password_reset_token) {
      this.errorResponse = {
        status: 422,
        errors: [{ message: "auth.invalid_confirm_params" }],
      };
    }
  }

  private submit() {
    this.passwordReset(this.payload)
      .then(() => (this.completed = true))
      .catch((errorResponse: ErrorResponse) => (this.errorResponse = errorResponse))
      .finally(() => this.form.reset());
  }
}
